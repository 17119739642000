import "../../scss/dist/ContactForm.css";
import React, { Component, useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
 function refreshPage() {
   window.location.reload(false);
 }
 const form = useRef();
 const sendEmail = (e) => {
   e.preventDefault();

   emailjs
     .sendForm(
       "service_83sgyt6",
       "template_qfusljm",
       form.current,
       "hPJkgpJNbV9Flflqi"
     )
     .then(
       (result) => {
         console.log(result.text);
         refreshPage();
       },
       (error) => {
         console.log(error.text);
       }
     );
 };
 return (
   <div className="ContactForm" id="contactus">
     <div className="Title">
       <h1>Leave us a message!</h1>
     </div>
     <form ref={form} onSubmit={sendEmail}>
       <label>NAME</label>
       <input
         type="text"
         name="user_name"
         placeholder="Full Name"
         className="textField"
       />
       <label>EMAIL</label>
       <input
         type="email"
         name="user_email"
         placeholder="EmailAdress@example.com"
         className="textField"
       />
       <label>MESSAGE</label>
       <textarea
         name="message"
         className="message"
         placeholder="Leave your message here "
       />
       <input className="btn" type="submit" value="Send" />
     </form>
   </div>
 );
};

export default ContactForm;
