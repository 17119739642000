import "../../scss/dist/Technologies.css";
import React, { Component } from "react";
import reactLogo from "../../Media/react.png";
import nodeLogo from "../../Media/nodejs.png";
import javascriptLogo from "../../Media/javascript.png";
import wordpressLogo from "../../Media/wordpress.png";
import html5Logo from "../../Media/html5.png";

class Technologies extends Component {
  render() {
    return (
      <div className="Technologies">
        <h1 className="Title">
          Technologies we use to engineer your awesome apps!
        </h1>
        <div className="Container contained">
          <div className="techLogo">
            <img src={reactLogo} alt="React" />
            <h3 className="techTitle">React</h3>
          </div>
          <div className="techLogo">
            <img src={javascriptLogo} alt="JavaScript" />
            <h3 className="techTitle">JavaScript</h3>
          </div>
          <div className="techLogo">
            <img src={nodeLogo} alt="NodeJS" />
            <h3 className="techTitle">Node.JS</h3>
          </div>
          <div className="techLogo">
            <img src={wordpressLogo} alt="WordPress" />
            <h3 className="techTitle">Wordpress</h3>
          </div>
          <div className="techLogo lastGrid">
            <img src={html5Logo} alt="HTML5" />
            <h3 className="techTitle">HTML5</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Technologies;
