import "../../scss/dist/Hero.css";
import background from "../../Media/mapBackground.svg";
import heroImage from "../../Media/HeroImage.png";
import React, { Component } from "react";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
export default class Hero extends Component {
  render() {
    const backgroundImage = { backgroundImage: `url(${background})` };
    return (
      <>
        <MouseParallaxContainer>
          <div className="Hero" style={backgroundImage}>
            <div className="HeroSegment">
              <div className="heroTitle">
                <h1>Websites Tailored to your business needs</h1>
                <div className="line" />
              </div>

              <p className="contained-l heroText">
                KamiKode is a regional web development company specializing in
                custom websites and e-commerce solutions for businesses of all
                sizes. Based in Beirut, Lebanon, our team of experienced
                developers is dedicated to helping our clients succeed online.
              </p>
            </div>
            <div className="HeroSegment">
              <MouseParallaxChild factorX={0.03} factorY={0.04}>
                <img className="contained-r" src={heroImage} alt="" />
              </MouseParallaxChild>
            </div>
          </div>
        </MouseParallaxContainer>
      </>
    );
  }
}
