import "../../scss/dist/Navbar.css";
import Logo from "../../Media/kamikode-wide.png";
import { React, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
export default function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  let menu;
  if (showMenu) {
    menu = (
      <div className="mobileMenu">
        <div
          className="mobileSideMenu empty"
          onClick={() => setShowMenu(!showMenu)}
        ></div>
        <div className="mobileSideMenu">
          <li>
            <a className="Xbutton" onClick={() => setShowMenu(!showMenu)}>
              <FontAwesomeIcon className="hamburger" icon={faXmark} />
            </a>
          </li>
          <li className="mobileNavItem">
            <NavLink exact activeClassName="active" to="/">
              Home
            </NavLink>
          </li>
          <li className="mobileNavItem">
            <NavLink activeClassName="active" to="/aboutUs">
              About us
            </NavLink>
          </li>

          <li className="mobileNavItem">
            <NavLink activeClassName="active" to="/ourTeam">
              Our Team
            </NavLink>
          </li>
          <li className="mobileNavItem">
            <NavLink activeClassName="active" to="/contactUs">
              Contact Us
            </NavLink>
          </li>
        </div>
      </div>
    );
  }
  return (
    <div className="MotherNav">
      <div className="Navbar">
        <li className="navbarLogoHolder navItem">
          <img className="navbarLogo" src={Logo} alt="" />
        </li>
        <li className="NavItem">
          <NavLink exact activeClassName="active" to="/">
            Home
          </NavLink>
        </li>
        <li className="NavItem">
          <NavLink activeClassName="active" to="/aboutUs">
            About us
          </NavLink>
        </li>
        <li className="NavItem">
          <NavLink activeClassName="active" to="/ourTeam">
            Our Team
          </NavLink>
        </li>
        <li className="NavItem">
          <NavLink activeClassName="active" to="/contactUs">
            Contact Us
          </NavLink>
        </li>
        <li>
          <a onClick={() => setShowMenu(!showMenu)}>
            <FontAwesomeIcon
              className="hamburger"
              icon={faBars}
            ></FontAwesomeIcon>
          </a>
        </li>
      </div>
      {menu}
    </div>
  );
}
