import React, { Component } from "react";
import Logo from "../../Media/logo.png";
import "../../scss/dist/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <div className="Box left-Box">
          <img className="footerLogo" src={Logo} alt="" />
          <h3 className="copyrights">©2023 KamiKode all rights reserved</h3>
        </div>
        <div className="Box">
          <h3 className="footerTitle">Follow Us On :</h3>
          <div className="Socials">
            <a href="https://www.facebook.com/KamiKode" target="_blank">
              <FontAwesomeIcon className="socialIcon" icon={faFacebookF} />
            </a>
            <a href="https://twitter.com/kami_kode" target="_blank">
              <FontAwesomeIcon className="socialIcon" icon={faTwitter} />
            </a>
            <a href="https://www.instagram.com/kami.kode/" target="_blank">
              <FontAwesomeIcon className="socialIcon" icon={faInstagram} />
            </a>
            <a href="https://www.linkedin.com/company/kamikode" target="_blank">
              <FontAwesomeIcon className="socialIcon" icon={faLinkedinIn} />
            </a>
          </div>
          <h3 className="footerTitle">Or Contact Us :</h3>
          <a
            href="mailto:support@KamiKode.com"
            target="_blank"
            className="footerText"
          >
            <FontAwesomeIcon className="contactSocials" icon={faEnvelope} />
            support@KamiKode.com
          </a>
          <a
            href="https://wa.me/96176099556"
            target="_blank"
            className="footerText"
          >
            <FontAwesomeIcon className="contactSocials" icon={faWhatsapp} />
            +961 76099556
          </a>
        </div>
        <div className="Box right-Box">
          <li className="footerNavItem">
            <a href="/" className="Home">
              Home
            </a>
          </li>
          <li className="footerNavItem">
            <a href="" className="Insight">
              Insight
            </a>
          </li>
          <li className="footerNavItem">
            <a href="/aboutUs" className="AboutUs">
              About us
            </a>
          </li>

          <li className="footerNavItem">
            <a href="/ourTeam" className="OurTeam">
              Our Team
            </a>
          </li>
          <li className="footerNavItem">
            <a href="/contactUs" className="ContactUs">
              Contact Us
            </a>
          </li>
        </div>
      </div>
    );
  }
}

export default Footer;
