import React, { Component } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import Technologies from "../../components/Technologies/Technologies";
import ContactForm from "../../components/ContactForm/ContactForm";

export default class Home extends Component {
  render() {
    return (
      <>
        <Hero />
        <Technologies />
        <ContactForm />
      </>
    );
  }
}
