import "../../scss/dist/OurTeam.css";
import React, { Component } from "react";
import Abstract from "../../Media/Abstract.png";
import AbstractR from "../../Media/AbstractR.png";
import mRifai from "../../Media/mrifai.jpeg";
import mChreif from "../../Media/mchreif.png";
import ace from "../../Media/ace.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
class OurTeam extends Component {
  render() {
    return (
      <div className="OurTeam">
        <MouseParallaxContainer className="mouse-parallax-container">
          <div className="TitleSection">
            <div className="AbstractHolder">
              <MouseParallaxChild factorX={0.03} factorY={0.05}>
                <img
                  className="Abstract A-Left"
                  src={Abstract}
                  alt="Abstract Image Left"
                />
              </MouseParallaxChild>
            </div>
            <div className="Title">
              <h4 className="firstTitle">Our Team</h4>
              <h3 className="secondTitle">Get to know us better</h3>
            </div>
            <div className="AbstractHolder">
              <MouseParallaxChild factorX={0.03} factorY={0.05}>
                <img
                  className="Abstract A-Right"
                  src={AbstractR}
                  alt="Abstract Image Left"
                />
              </MouseParallaxChild>
            </div>
          </div>
          <div className="MembersSection">
            <div className="MemberCard">
              <img className="memberImage" src={mRifai} alt="" />
              <div className="memberName">Mohammed Kassem El-Rifaii</div>
              <div className="memberTitle">CEO & Founder / Head Dev</div>
              <div className="memberSocials">
                <a
                  href="https://www.linkedin.com/in/mohammed-kassem-el-rifaii/"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    className="memberSocial"
                    icon={faLinkedinIn}
                  />
                </a>
              </div>
            </div>
            <div className="MemberCard">
              <img className="memberImage" src={mChreif} alt="" />
              <div className="memberName">Mohammed Chreif</div>
              <div className="memberTitle">CEO & Founder / Head Dev</div>
              <div className="memberSocials">
                <a href="https://www.instagram.com/mochr.dev/" target="_blank">
                  <FontAwesomeIcon
                    className="memberSocial"
                    icon={faInstagram}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/mohammad-c/"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    className="memberSocial"
                    icon={faLinkedinIn}
                  />
                </a>
                <a href="https://twitter.com/mohchrf" target="_blank">
                  <FontAwesomeIcon className="memberSocial" icon={faTwitter} />
                </a>
              </div>
            </div>
            <div className="MemberCard">
              <img className="memberImage" src={ace} alt="" />
              <div className="memberName">Gold Ace</div>
              <div className="memberTitle">Head Software Developer</div>
              <div className="memberSocials">
                <a href="#">
                  <FontAwesomeIcon
                    className="memberSocial"
                    icon={faInstagram}
                  />
                </a>
                <a href="#">
                  <FontAwesomeIcon
                    className="memberSocial"
                    icon={faLinkedinIn}
                  />
                </a>
                <a href="#">
                  <FontAwesomeIcon className="memberSocial" icon={faTwitter} />
                </a>
              </div>
            </div>
          </div>
        </MouseParallaxContainer>
      </div>
    );
  }
}

export default OurTeam;
