import React, { Component } from "react";
import "../../scss/dist/AboutUs.css";
import Abstract from "../../Media/Abstract.png";
import AbstractR from "../../Media/AbstractR.png";
import Logo from "../../Media/logo.png";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
class AboutUs extends Component {
  render() {
    return (
      <div className="AboutUsSec">
        <MouseParallaxContainer>
          <div className="Section">
            <div className="AbstractHolder">
              <MouseParallaxChild factorX={0.03} factorY={0.05}>
                <img className="Abstract A-Left" src={Abstract} alt="" />
              </MouseParallaxChild>
            </div>
            <MouseParallaxChild factorX={0.01} factorY={0.01}>
              <img className="Logo" src={Logo} alt="" />
            </MouseParallaxChild>
            <div className="AbstractHolder">
              <MouseParallaxChild factorX={0.03} factorY={0.05}>
                <img className="Abstract A-Right" src={AbstractR} alt="" />
              </MouseParallaxChild>
            </div>
          </div>
          <div className="Section">
            <div className="AboutText">
              <p>
                KamiKode is a leading regional web development company based in
                Beirut, Lebanon. Our team of highly skilled and experienced
                developers specialize in creating professional and innovative
                websites using a range of programming languages and
                technologies. We offer a wide range of services, including
                custom website development, e-commerce solutions, and search
                engine optimization, to meet the unique needs and goals of our
                clients. At KamiKode, we pride ourselves on our strong focus on
                customer satisfaction and our dedication to helping our clients
                succeed online. Whether you're a small startup or a large
                corporation, KamiKode has the expertise and experience to take
                your online presence to the next level.
              </p>
            </div>
          </div>
        </MouseParallaxContainer>
      </div>
    );
  }
}

export default AboutUs;
