import React, { Component } from "react";
import ContactForm from "../../components/ContactForm/ContactForm";

class ContactUs extends Component {
  render() {
    return (
      <>
        <ContactForm />
      </>
    );
  }
}

export default ContactUs;
